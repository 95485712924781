/* eslint-disable no-shadow */
import * as types from '../mutation-types';
import * as api from '../../lib/api';

const state = {
  baseCurrency: 'EUR',
  authToken: null,
  isLoggedIn: false,
  isLoading: false,
  account: {
    customer: {},
    user: {},
  },
};

const mutations = {
  [types.SET_AUTH_TOKEN](state, token) {
    state.authToken = token;
  },

  [types.SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },

  [types.SET_IS_LOGGED_IN](state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },

  [types.SET_CUSTOMER](state, customer) {
    state.account.customer = {
      ...customer,
      transactionsDeletedFrom: new Date(customer.transactionsDeletedFrom),
    };
  },

  [types.SET_USER](state, user) {
    state.account.user = user;
  },
};

const actions = {
  setAuthToken({ commit }, token) {
    commit(types.SET_AUTH_TOKEN, token);
    api.setAuthToken(token);
  },

  async login({ dispatch, commit }, tempToken) {
    const token = await api.tokenExchange({ token: tempToken });
    dispatch('setAuthToken', token);
    commit(types.SET_IS_LOGGED_IN, true);
  },

  async load({ commit }) {
    const { customer, user } = await api.getAccount();

    commit(types.SET_CUSTOMER, customer);
    commit(types.SET_USER, user);
  },

  async checkIsLoggedIn({ commit }) {
    commit(types.SET_IS_LOADING, true);
    try {
      await api.getTransactions();
      commit(types.SET_IS_LOADING, false);
      commit(types.SET_IS_LOGGED_IN, true);
    } catch (error) {
      commit(types.SET_IS_LOGGED_IN, false);
      commit(types.SET_IS_LOADING, false);
      commit(types.SET_AUTH_TOKEN, null);
    }
  },

  updateCustomer({ commit }, customer) {
    commit(types.SET_CUSTOMER, customer);
  },
};

const getters = {
  getBaseCurrency(state) {
    return state.baseCurrency;
  },

  getAccount(state) {
    return state.account;
  },

  isLoggedIn(state) {
    return state.isLoggedIn;
  },

  isLoading() {
    return state.isLoading;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
