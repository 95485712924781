// store.js
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['general'],
  reducer: (state) => ({
    general: {
      authToken: state.general.authToken,
    },
  }),
});

// Load all modules.
function loadModules() {
  const context = require.context('./modules', false, /([a-z_]+)\.js$/i);

  const modules = context
    .keys()
    .map((key) => ({ key, name: key.match(/([a-z_]+)\.js$/i)[1] }))
    .reduce(
      (_modules, { key, name }) => ({
        ..._modules,
        [name]: context(key).default,
      }),
      {},
    );

  return { context, modules };
}

const { context, modules } = loadModules();

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  plugins: [vuexLocal.plugin],
});

if (module.hot) {
  // Hot reload whenever any module changes.
  module.hot.accept(context.id, () => {
    const result = loadModules();

    store.hotUpdate({
      modules: result.modules,
    });
  });
}

export default store;
