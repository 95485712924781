/* eslint-disable no-shadow */
import Ledger from '../../lib/Ledger';
import * as types from '../mutation-types';
import helpers from '../helpers';

const state = {
  items: {},
  totalCost: 0,
};

const mutations = {
  [types.ADD_ASSETS](state, assets) {
    helpers.mutations.createItems(state, assets);
  },

  [types.SET_TOTAL_COST](state, totalCost) {
    state.totalCost = totalCost;
  },

  [types.RESET](state) {
    state.totalCost = 0;
    state.items = {};
  },
};

const actions = {
  reset({ commit }) {
    commit(types.RESET);
  },

  calculate({ commit, dispatch, rootGetters }) {
    dispatch('reset');
    const transactions = rootGetters['transactions/getAll'];
    const stockSplits = rootGetters['stockSplits/getAll'];
    const ledger = new Ledger(transactions);

    stockSplits.forEach(({ assetId, split, date }) => {
      ledger.getAsset(assetId).applyStockSplit(split, date);
    });

    ledger.calculate();

    const { assets, totalCost } = ledger.toJSON();

    commit(types.ADD_ASSETS, assets);
    commit(types.SET_TOTAL_COST, totalCost);
  },
};

const getters = {
  getAssets(state) {
    return Object.values(state.items);
  },

  getAllTransactions(state) {
    return Object.values(state.items).flatMap(({ transactions }) => (transactions));
  },

  getAsset(state) {
    return (id) => state.items[id];
  },

  getTotalCost(state) {
    return state.totalCost;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
