export default class Asset {
  constructor({ assetId, transactions }) {
    this.assetId = assetId;
    this.transactions = transactions;

    this.transactions.forEach((transaction, index) => {
      transaction.setNext(this.transactions[index + 1]);
      transaction.setPrevious(this.transactions[index - 1]);
      transaction.setHead(this.transactions[0]);
    });
  }

  calculate() {
    this.transactions.forEach((transaction) => {
      transaction.calculate();
    });
  }

  applyStockSplit(split, date) {
    this.transactions.forEach((transaction) => {
      if (transaction.datetime.getTime() < date.getTime()) {
        transaction.split(split);
      }
    });
  }

  getProfit() {
    return this.transactions.reduce((acc, record) => record.getProfit() + acc, 0);
  }

  getNumSharesLeft() {
    return this.transactions.reduce((acc, record) => record.sharesLeft + acc, 0);
  }

  getCostOfSharesLeft() {
    return this.transactions.reduce((acc, record) => record.getCostOfSharesLeft() + acc, 0);
  }

  toJSON() {
    return {
      id: this.assetId,
      profit: this.getProfit(),
      sharesLeft: this.getNumSharesLeft(),
      costOfSharesLeft: this.getCostOfSharesLeft(),
      transactions: this.transactions.map((transaction) => transaction.toJSON()),
    };
  }
}
