<template>
  <div class="page-register">
    <img
      src="https://app.tallytax.ie/img/icons/base_icon.svg"
      class="page-register__logo"
    >
    <validation-observer
      slim
      v-slot="{ invalid }"
      v-if="loadingState !== 'loaded'"
    >
      <cv-form
        class="page-register__form"
        @submit.prevent="register"
      >
        <validation-provider
          rules="required"
          name="name"
          slim
          v-slot="{ errors }"
        >
          <cv-text-input
            label="Name"
            v-model="name"
            placeholder="Joe Tax"
            :invalid-message="errors[0]"
          />
        </validation-provider>
        <validation-provider
          rules="required|email"
          name="email"
          v-slot="{ errors }"
          slim
        >
          <cv-text-input
            label="Email"
            v-model="email"
            type="email"
            placeholder="example@example.com"
            :invalid-message="errors[0]"
          />
        </validation-provider>
        <cv-button :disabled="invalid || loadingState === 'loading'">
          Register
        </cv-button>

        <cv-inline-loading
          v-if="loadingState && loadingState !== 'error'"
          :state="loadingState"
          loading-text="Loading..."
        />

        <cv-toast-notification
          v-if="loadingState === 'error'"
          kind="error"
          title="Failed to sign up"
          :sub-title="signupError"
        />
      </cv-form>
    </validation-observer>
    <div
      class="page-register__success"
      v-if="loadingState === 'loaded'"
    >
      <h1>Welcome!</h1>

      <p>Check your email and follow the link to get started.</p>
    </div>
  </div>
</template>

<script>
import {
  CvForm,
  CvTextInput,
  CvButton,
  CvInlineLoading,
  CvToastNotification,
} from '@carbon/vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm';
import { signup } from '../lib/api';

export default {
  name: 'Register',
  components: {
    CvForm,
    CvTextInput,
    CvButton,
    ValidationProvider,
    ValidationObserver,
    CvInlineLoading,
    CvToastNotification,

  },

  data() {
    return {
      name: '',
      email: '',
      loadingState: '',
      signupError: '',
    };
  },

  methods: {
    async register() {
      this.loadingState = 'loading';
      try {
        await signup({ name: this.name, email: this.email });
      } catch (error) {
        this.loadingState = 'error';
        this.signupError = error.response.data.message;
        return;
      }

      this.loadingState = 'loaded';
    },
  },
};
</script>

<style>
.page-register {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.page-register__form {
  width: 15rem;
}

.page-register__form > * {
  margin-bottom: 1rem;
}

.page-register__success {
  text-align: center;
}

.page-register__success > * {
  margin-bottom: 1rem;
}

.page-register__logo {
  display: block;
  width: 160px;
  margin-bottom: 2rem;
}
</style>
