var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"make-the-carbon-theme-go-white",attrs:{"id":"app"}},[_c('global-styles'),(_vm.shouldShowTopBar)?_c('cv-header',{attrs:{"aria-label":"Carbon header"},scopedSlots:_vm._u([{key:"header-global",fn:function(){return [_c('cv-header-global-action',{attrs:{"aria-label":"Log out","aria-controls":"log-out-panel","label":"Log out","tip-position":"bottom","tip-alignment":"start"},on:{"click":_vm.logout}},[_c('Logout20')],1),_c('cv-header-global-action',{attrs:{"aria-label":"Settings","aria-controls":"settings","label":"Settings","tip-position":"bottom","tip-alignment":"end"},on:{"click":_vm.settings}},[_c('Settings20')],1)]},proxy:true},{key:"left-panels",fn:function(){return [_c('cv-side-nav',{attrs:{"id":"side-nav","fixed":""}},[_c('cv-side-nav-items',[_c('cv-header-side-nav-items',[_c('router-link',{attrs:{"to":"/transactions","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('cv-header-menu-item',{attrs:{"href":href,"active":isActive},on:{"click":navigate}},[_vm._v(" Transactions ")])]}}],null,false,185698871)}),_c('router-link',{attrs:{"to":"/assets","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('cv-header-menu-item',{attrs:{"href":href,"active":isActive},on:{"click":navigate}},[_vm._v(" Assets ")])]}}],null,false,2963271651)}),_c('router-link',{attrs:{"to":"/reports","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('cv-header-menu-item',{attrs:{"href":href,"active":isActive},on:{"click":navigate}},[_vm._v(" Reports ")])]}}],null,false,3995360733)})],1)],1)],1)]},proxy:true}],null,false,1505494794)},[_c('cv-header-menu-button',{attrs:{"aria-label":"Header menu","aria-controls":"side-nav"}}),_c('cv-skip-to-content',{attrs:{"href":"#main-content"}},[_vm._v(" Skip to content ")]),_c('cv-header-name',{attrs:{"href":"javascript:void(0)","prefix":"tallytax.ie"}},[_vm._v(" Captial gains helper ")]),_c('cv-header-nav',{attrs:{"aria-label":"tallytax.ie nav"}},[_c('router-link',{attrs:{"to":"/transactions","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('cv-header-menu-item',{attrs:{"href":href,"active":isActive},on:{"click":navigate}},[_vm._v(" Transactions ")])]}}],null,false,185698871)}),_c('router-link',{attrs:{"to":"/assets","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('cv-header-menu-item',{attrs:{"href":href,"active":isActive},on:{"click":navigate}},[_vm._v(" Assets ")])]}}],null,false,2963271651)}),_c('router-link',{attrs:{"to":"/reports","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('cv-header-menu-item',{attrs:{"href":href,"active":isActive},on:{"click":navigate}},[_vm._v(" Reports ")])]}}],null,false,3995360733)})],1)],1):_vm._e(),_c('section',{staticClass:"page-body bx--grid"},[(this.hasLoaded || !_vm.isAuthedRoute)?_c('router-view'):_vm._e()],1),_vm._m(0),_c('cv-loading',{attrs:{"active":_vm.isLoadingInitial,"overlay":true}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"page-footer bx--grid"},[_c('span',[_vm._v("tallytax.ie 2022 | Terms of service")])])}]

export { render, staticRenderFns }