/* eslint-disable no-shadow */

import _ from 'lodash';
import * as types from '../mutation-types';
import helpers from '../helpers';
import { getTransactions } from '../../lib/api';
import { transform } from '../helpers/transaction';

const state = {
  items: {},
};

const mutations = {
  [types.ADD_TRANSACTIONS](state, transactions) {
    helpers.mutations.createItems(state, transactions, transform);
  },

  [types.UPDATE_TRANSACTIONS](state, transactions) {
    helpers.mutations.updateItems(state, transactions, transform);
  },
};

const actions = {
  add({ commit }, transactions) {
    commit(types.ADD_TRANSACTIONS, transactions);
  },

  update({ commit }, transactions) {
    commit(types.UPDATE_TRANSACTIONS, transactions);
  },

  async load({ dispatch, getters }) {
    let transactions = [];
    do {
      const cursor = getters.getLastUpdatedId;
      // eslint-disable-next-line no-await-in-loop
      transactions = await getTransactions({ cursor });
      dispatch('add', transactions);
    } while (transactions.length);
  },
};

const getters = {
  get(state) {
    return (id) => state.items[id];
  },

  getAll(state, getters, rootState) {
    const { customer } = rootState.general.account;
    console.log('customer.transactionsDeletedFrom', customer.transactionsDeletedFrom);
    return Object.values(state.items).filter(({ isDeleted, createdAt }) => (
      !isDeleted && customer.transactionsDeletedFrom < createdAt
    ));
  },

  getByImportId(state) {
    return (importId) => Object.values(state.items).find(
      (item) => (item.importId === importId),
    );
  },

  getLastUpdatedId(state) {
    return _.orderBy(Object.values(state.items), ['updatedAt', 'id'], ['desc', 'desc'])[0]?.id;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
