export const ADD_TRANSACTIONS = 'ADD_TRANSACTIONS';
export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTIONS';

export const ADD_ASSETS = 'ADD_ASSETS';
export const UPDATE_ASSETS = 'UPDATE_ASSETS';
export const MAP_USER_TICKER_ASSET_DETAILS = 'MAP_USER_TICKER_ASSET_DETAILS';

export const SET_TOTAL_COST = 'SET_TOTAL_COST';
export const RESET = 'RESET';

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_USER = 'SET_USER';
export const ADD_IMPORT_PRESETS = 'ADD_IMPORT_PRESETS';

export const ADD_STOCK_SPLITS = 'ADD_STOCK_SPLITS';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
