<template>
  <cv-toast-notification
    :kind="kind"
    :title="message"
    :sub-title="subTitle"
    :caption="caption"
    @close="$emit('remove')"
    close-aria-label="Close"
    low-contrast
  />
</template>

<script>
import ToastMixin from 'vue-my-toasts/src/mixins/ToastMixin';
import TimerMixin from 'vue-my-toasts/src/mixins/TimerMixin';

import {
  CvToastNotification,
} from '@carbon/vue';

export default {
  name: 'CarbonToast',

  mixins: [ToastMixin, TimerMixin],

  props: {
    subTitle: {
      type: String,
      required: false,
      default: '',
    },
    caption: {
      type: String,
      required: false,
      default: '',
    },
  },

  components: {
    CvToastNotification,
  },

  computed: {
    kind() {
      switch (this.type) {
        case 'base':
          return 'info';
        case 'warning':
          return 'warning';
        case 'error':
          return 'error';
        case 'success':
          return 'success';
        default:
          return 'info';
      }
    },
  },
};
</script>

<style scoped>

</style>
