import axios from 'axios';

const authed = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
});

let authToken = '';

authed.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    };

    return config;
  },
  (error) => {
    throw error;
  },
);

const unauthed = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
});

export const setAuthToken = (token) => {
  authToken = token;
};

export const signup = ({ name, email }) => unauthed.post('/signup', { name, email });

export const getAccount = async () => {
  const { data } = await authed.get('/account');
  return data;
};

export const tokenExchange = async ({ token }) => {
  const { data } = await unauthed.post('/token-ex', { token });
  return data.token;
};

export const login = async ({ name, email }) => {
  const { data } = await unauthed.post('/login', { name, email });
  return data.token;
};

export const getTransactions = async ({ cursor, take = 100 } = {}) => {
  const { data } = await authed.get('/transactions', { params: { cursor, take } });
  return data;
};

export const getAssets = async ({ cursor, take = 100 } = {}) => {
  const { data } = await authed.get('/assets', { params: { cursor, take } });
  return data;
};

export const getImportPresets = async ({ cursor, take = 100 } = {}) => {
  const { data } = await authed.get('/import-presets', { params: { cursor, take } });
  return data;
};

export const getStockSplits = async ({ cursor, take = 100 } = {}) => {
  const { data } = await authed.get('/stock-splits', { params: { cursor, take } });
  return data;
};

export const addStockSplit = async (split) => {
  const { data } = await authed.post('/stock-splits', split);
  return data;
};

export const addImportPreset = async (preset) => {
  const { data } = await authed.post('/import-presets', preset);
  return data;
};

export const addAssets = async (assets) => {
  const { data } = await authed.post('/assets/batch', assets);
  return data;
};

export const addTrasactions = async (transactions) => {
  const { data } = await authed.post('/transactions/batch', transactions);
  return data;
};

export const updateTransaction = async (id, transaction) => {
  const { data } = await authed.patch(`/transactions/${id}`, transaction);
  return data;
};

export const updateAsset = async (id, asset) => {
  const { data } = await authed.patch(`/assets/${id}`, asset);
  return data;
};

export const getPayments = async () => {
  const { data } = await authed.get('/payments');
  return data;
};

export const getProducts = async ({ cursor, take = 100 } = {}) => {
  const { data } = await authed.get('/products', { params: { cursor, take } });
  return data;
};

export const addSession = async (session) => {
  const { data } = await authed.post('/session', session);
  return data;
};

export const updateCustomer = async (id, customer) => {
  const { data } = await authed.patch(`/customers/${id}`, customer);
  return data;
};
