/* eslint-disable no-shadow */

import * as types from '../mutation-types';
import helpers from '../helpers';
import { getProducts } from '@/lib/api';
import { transform } from '../helpers/products';

const state = {
  items: {},
};

const mutations = {
  [types.ADD_PRODUCTS](state, presets) {
    helpers.mutations.createItems(state, presets, transform);
  },
};

const actions = {
  add({ commit }, presets) {
    commit(types.ADD_PRODUCTS, presets);
  },

  async load({ commit }) {
    const presets = await getProducts();

    commit(types.ADD_PRODUCTS, presets);
  },
};

const getters = {
  get(state) {
    return (id) => state.items[id];
  },

  getAll(state) {
    return Object.keys(state.items).map((id) => state.items[id]);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
