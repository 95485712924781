/* eslint-disable no-param-reassign */

import omit from 'lodash/omit';

const createItems = (state, items, transform) => {
  if (transform) items = items.map(transform);
  items = items.reduce((accumulator, item) => {
    accumulator[item.id] = item;
    return accumulator;
  }, {});

  state.items = { ...state.items, ...items };
};

const updateItems = (state, items, transform) => {
  if (transform) items = items.map(transform);

  items.forEach((item) => {
    if (state.items[item.id]) {
      Object.assign(state.items[item.id], item);
    }
  });
};

const deleteItems = (state, items) => {
  state.items = omit(state.items, items.map((item) => item.id));
};

export default {
  createItems,
  updateItems,
  deleteItems,
};
