import differenceInDays from 'date-fns/differenceInDays';

export default class Transaction {
  constructor({
    type,
    quantity,
    datetime,
    exchangeRate,
    id,
    assetId,
    price,
  }) {
    this.type = type;
    this.quantity = quantity;
    this.datetime = datetime;
    this.exchangeRate = exchangeRate;
    this.id = id;
    this.assetId = assetId;
    this.price = price;
    this.reset();
  }

  reset() {
    this.sharesLeft = this.type === 'BUY' ? this.quantity : 0;
    this.profit = 0;
    this.percentageOfLossConsumed = 0;
    this.localPrice = this.caculateExchange(this.price);
  }

  consumeSaleLosses(buyTx, sharesRemainingToBeConsumed, lossValue = 0) {
    if (!sharesRemainingToBeConsumed) return lossValue;
    if (differenceInDays(buyTx.datetime, this.datetime) > 28) return lossValue;

    const sharesAvailable = this.quantity - (this.quantity * this.percentageOfLossConsumed);
    const profitAvailable = this.profit - (this.profit * this.percentageOfLossConsumed);

    if (this.type === 'BUY' || profitAvailable >= 0) {
      return this.previous
        ? this.previous.consumeSaleLosses(buyTx, sharesRemainingToBeConsumed, lossValue)
        : lossValue;
    }

    if (sharesRemainingToBeConsumed < sharesAvailable) {
      const percentageOfLossToConsume = sharesRemainingToBeConsumed / this.quantity;
      this.percentageOfLossConsumed += percentageOfLossToConsume;
      return lossValue + (this.profit * percentageOfLossToConsume);
    }

    this.percentageOfLossConsumed = 1;

    return this.previous.consumeSaleLosses(
      buyTx,
      sharesRemainingToBeConsumed - sharesAvailable,
      lossValue + profitAvailable,
    );
  }

  calculate() {
    if (this.type === 'BUY') {
      // 4 week rule
      const loss = this.consumeSaleLosses(this, this.quantity);

      // increase the price per share based on the loss incurred
      this.localPrice += Math.abs(loss) / this.quantity;
      return;
    }

    if (!this.previous) {
      this.failure = 'NO_PREVIOUS_BUY';
      this.profit = 0;
      return;
    }

    const recentlyPurchased = this.previous.sell({
      sellTx: this,
      numShares: this.quantity,
      fifo: false,
      exitWhen: (buyTx) => differenceInDays(this.datetime, buyTx.datetime) > 28,
    });

    const { profit, sharesSold } = this.head.sell({
      sellTx: this,
      numShares: this.quantity - recentlyPurchased.sharesSold,
      fifo: true,
    });

    const totalSold = sharesSold + recentlyPurchased.sharesSold;
    if (totalSold !== this.quantity) {
      this.failure = 'NOT_ENOUGH_BUYS';
    }

    this.profit = profit + recentlyPurchased.profit;
  }

  getCostOfSharesLeft() {
    return this.sharesLeft * this.localPrice;
  }

  caculateExchange(price) {
    const rate = 1 / this.exchangeRate;
    return rate * price;
  }

  sell({
    sellTx, numShares, fifo, exitWhen = () => false, acc = { sharesSold: 0, profit: 0 },
  }) {
    const neighbor = fifo ? this.next : this.previous;

    if (!numShares) return acc;

    if (neighbor && this.type === 'SELL') {
      return neighbor.sell({
        sellTx, numShares, fifo, exitWhen, acc,
      });
    }
    if (exitWhen(this)) return acc;

    const numSold = numShares - this.sharesLeft >= 0
      ? this.sharesLeft : numShares;

    this.sharesLeft -= numSold;

    const numLeftToSell = numShares - numSold;

    const buyPrice = this.localPrice;

    const profit = (numSold * sellTx.localPrice) - (numSold * buyPrice);

    acc.sharesSold += numSold;
    acc.profit += profit;

    return neighbor ? neighbor.sell({
      sellTx, numShares: numLeftToSell, fifo, exitWhen, acc,
    }) : acc;
  }

  setPrevious(record) {
    this.previous = record;
  }

  setNext(record) {
    this.next = record;
  }

  setHead(record) {
    this.head = record;
  }

  split(split) {
    this.quantity *= split;
    this.price /= split;
    this.localPrice = this.caculateExchange(this.price);
    this.sharesLeft = this.type === 'BUY' ? this.quantity : 0;
  }

  getProfit() {
    return this.profit - (this.profit * this.percentageOfLossConsumed);
  }

  toJSON() {
    return {
      id: this.id,
      assetId: this.assetId,
      type: this.type,
      profit: this.getProfit(),
      sharesLeft: this.sharesLeft,
      price: this.price,
      exchangeRate: this.exchangeRate,
      costOfSharesLeft: this.getCostOfSharesLeft(),
      datetime: this.datetime,
      quantity: this.quantity,
      localPrice: this.localPrice,
      failure: this.failure,
    };
  }
}
