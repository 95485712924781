import Vue from 'vue';
import VueMyToasts from 'vue-my-toasts';
import 'vue-my-toasts/dist/vue-my-toasts.css';
import Toast from './components/Toast.vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  mounted(el) {
    // Focus the element
    Vue.nextTick(() => el.focus());
  },
});

Vue.use(VueMyToasts, {
  component: Toast,
  options: {
    width: '320px',
    position: 'top-middle',
    padding: '1rem',
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
