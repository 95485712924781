<template>
  <div />
</template>

<script>
import 'carbon-components/css/carbon-components.css';

export default {};
</script>

<style lang="scss">

// $feature-flags: (
//   enable-css-custom-properties: true,
// );

// @import "carbon-components/scss/globals/scss/styles.scss";
// //@import "~@carbon/themes/scss";

// // Use the gray 10 theme by default
// $carbon--theme: $carbon--theme--g10;

// // Use the white theme wherever this class is added
// .make-the-carbon-theme-go-white {
//   @include carbon--theme($theme: $carbon--theme--white, $emit-custom-properties: true);
// }

// // Use the g100 theme wherever this class is added
// .make-the-carbon-theme-go-really-dark {
//   @include carbon--theme($theme: $carbon--theme--g100, $emit-custom-properties: true);
// }
</style>
