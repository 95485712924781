import { groupBy } from 'lodash';
import Asset from './Asset';
import Transaction from './Transaction';

export default class Ledger {
  constructor(records) {
    this.transactions = records
      .filter(({ type }) => ['BUY', 'SELL'].includes(type))
      .sort((a, b) => a.datetime - b.datetime)
      .map((record) => new Transaction(record));

    const transactionsGroupedByAsset = groupBy(this.transactions, 'assetId');

    this.assets = Object.keys(transactionsGroupedByAsset).reduce((acc, assetId) => {
      acc[assetId] = new Asset({ assetId, transactions: transactionsGroupedByAsset[assetId] });
      return acc;
    }, {});
  }

  calculate() {
    Object.keys(this.assets).forEach((assetId) => this.assets[assetId].calculate());
  }

  getAsset(assetId) {
    return this.assets[assetId];
  }

  getAssets() {
    return Object.values(this.assets);
  }

  getTotalCost() {
    // eslint-disable-next-line max-len
    return this.transactions.reduce((acc, transaction) => acc + transaction.getCostOfSharesLeft(), 0);
  }

  toJSON() {
    return {
      assets: this.getAssets().map((asset) => asset.toJSON()),
      totalCost: this.getTotalCost(),
    };
  }
}
