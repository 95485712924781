import Vue from 'vue';
import VueRouter from 'vue-router';
import Register from '../views/Register.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    redirect: '/transactions',
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import(/* webpackChunkName: "about" */ '../views/Transactions/Transactions.vue'),
  },
  {
    path: '/transactions/import',
    name: 'Import transactions',
    component: () => import(/* webpackChunkName: "import" */ '../views/Transactions/Import.vue'),
  },
  {
    path: '/transactions/add',
    name: 'Add transactions',
    component: () => import(/* webpackChunkName: "add-transaction" */ '../views/Transactions/AddEdit.vue'),
  },
  {
    path: '/assets/:assetId/transactions/add',
    name: 'Add transactions to asset',
    component: () => import(/* webpackChunkName: "add-transaction" */ '../views/Transactions/AddEdit.vue'),
  },
  {
    path: '/transactions/:transactionId/edit',
    name: 'Edit transactions',
    component: () => import(/* webpackChunkName: "add-transaction" */ '../views/Transactions/AddEdit.vue'),
  },
  {
    path: '/assets',
    name: 'Assets',
    component: () => import(/* webpackChunkName: "assets" */ '../views/Assets/Assets.vue'),
  },
  {
    path: '/assets/:id',
    name: 'Asset',
    component: () => import(/* webpackChunkName: "asset" */ '../views/Asset/Asset.vue'),
  },
  {
    path: '/reports/:start/:end/:action',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports/Reports.vue'),
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports/Reports.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Settings.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
