<template>
  <div class="page-login">
    <img
      src="https://app.tallytax.ie/img/icons/base_icon.svg"
      class="page-login__logo"
    >
    <validation-observer
      slim
      v-slot="{ invalid }"
      v-if="loadingState !== 'loaded' && !isLoggingIn"
    >
      <cv-form
        class="page-login__form"
        @submit.prevent="login"
      >
        <validation-provider
          rules="required|email"
          name="email"
          v-slot="{ errors }"
          slim
        >
          <cv-text-input
            label="Email"
            v-model="email"
            type="email"
            placeholder="example@example.com"
            :invalid-message="errors[0]"
          />
        </validation-provider>
        <cv-button :disabled="invalid || loadingState === 'loading'">
          Sign in
        </cv-button>

        <cv-inline-loading
          v-if="loadingState && loadingState !== 'error'"
          :state="loadingState"
          loading-text="Logging in..."
        />
      </cv-form>
    </validation-observer>
    <div
      class="page-login__success"
      v-if="loadingState === 'loaded' && !isLoggingIn"
    >
      <h1>Welcome!</h1>

      <p>We sent you an email, follow the link within to log in.</p>
    </div>
    <cv-loading
      :active="isLoggingIn"
      :overlay="true"
    />
  </div>
</template>

<script>
import {
  CvForm,
  CvTextInput,
  CvButton,
  CvInlineLoading,
  CvLoading,
} from '@carbon/vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm';
import { login } from '../lib/api';

export default {
  name: 'Login',
  components: {
    CvForm,
    CvTextInput,
    CvButton,
    ValidationProvider,
    ValidationObserver,
    CvInlineLoading,
    CvLoading,
  },

  data() {
    return {
      email: '',
      loadingState: '',
      loginError: '',
      isLoggingIn: false,
    };
  },

  computed: {
    token() {
      return this.$route.query.token;
    },

    hasToken() {
      return !!this.token;
    },
  },

  methods: {
    async login() {
      this.loadingState = 'loading';
      try {
        await login({ email: this.email.trim() });
      } catch (error) {
        this.loadingState = 'error';
        this.$toasts.error('Failed to login', { subTitle: error.message });
        return;
      }

      this.loadingState = 'loaded';
    },
  },

  async mounted() {
    if (this.hasToken) {
      this.isLoggingIn = true;
      try {
        await this.$store.dispatch('general/login', this.token);
        this.$router.push('/');
      } catch (error) {
        console.log(error);
        this.isLoggingIn = false;
        this.loginError = 'Failed to log in, try again';
      }
    }
  },
};
</script>

<style>
.page-login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.page-login__form {
  width: 15rem;
}

.page-login__form > * {
  margin-bottom: 1rem;
}

.page-login__success {
  text-align: center;
}

.page-login__success > * {
  margin-bottom: 1rem;
}

.page-login__logo {
  display: block;
  width: 160px;
  margin-bottom: 2rem;
}
</style>
