<template>
  <div
    id="app"
    class="make-the-carbon-theme-go-white"
  >
    <global-styles />
    <cv-header
      aria-label="Carbon header"
      v-if="shouldShowTopBar"
    >
      <cv-header-menu-button
        aria-label="Header menu"
        aria-controls="side-nav"
      />
      <cv-skip-to-content href="#main-content">
        Skip to content
      </cv-skip-to-content>
      <cv-header-name
        href="javascript:void(0)"
        prefix="tallytax.ie"
      >
        Captial gains helper
      </cv-header-name>
      <cv-header-nav aria-label="tallytax.ie nav">
        <router-link
          to="/transactions"
          custom
          v-slot="{ href, navigate, isActive }"
        >
          <cv-header-menu-item
            :href="href"
            @click="navigate"
            :active="isActive"
          >
            Transactions
          </cv-header-menu-item>
        </router-link>
        <router-link
          to="/assets"
          custom
          v-slot="{ href, navigate, isActive }"
        >
          <cv-header-menu-item
            :href="href"
            @click="navigate"
            :active="isActive"
          >
            Assets
          </cv-header-menu-item>
        </router-link>
        <router-link
          to="/reports"
          custom
          v-slot="{ href, navigate, isActive }"
        >
          <cv-header-menu-item
            :href="href"
            @click="navigate"
            :active="isActive"
          >
            Reports
          </cv-header-menu-item>
        </router-link>
      </cv-header-nav>
      <template v-slot:header-global>
        <cv-header-global-action
          aria-label="Log out"
          aria-controls="log-out-panel"
          label="Log out"
          tip-position="bottom"
          tip-alignment="start"
          @click="logout"
        >
          <Logout20 />
        </cv-header-global-action>
        <cv-header-global-action
          aria-label="Settings"
          aria-controls="settings"
          label="Settings"
          tip-position="bottom"
          tip-alignment="end"
          @click="settings"
        >
          <Settings20 />
        </cv-header-global-action>
      </template>
      <template
        v-slot:left-panels
      >
        <cv-side-nav
          id="side-nav"
          fixed
        >
          <cv-side-nav-items>
            <cv-header-side-nav-items>
              <router-link
                to="/transactions"
                custom
                v-slot="{ href, navigate, isActive }"
              >
                <cv-header-menu-item
                  :href="href"
                  @click="navigate"
                  :active="isActive"
                >
                  Transactions
                </cv-header-menu-item>
              </router-link>
              <router-link
                to="/assets"
                custom
                v-slot="{ href, navigate, isActive }"
              >
                <cv-header-menu-item
                  :href="href"
                  @click="navigate"
                  :active="isActive"
                >
                  Assets
                </cv-header-menu-item>
              </router-link>
              <router-link
                to="/reports"
                custom
                v-slot="{ href, navigate, isActive }"
              >
                <cv-header-menu-item
                  :href="href"
                  @click="navigate"
                  :active="isActive"
                >
                  Reports
                </cv-header-menu-item>
              </router-link>
            </cv-header-side-nav-items>
          </cv-side-nav-items>
        </cv-side-nav>
      </template>
    </cv-header>
    <section
      class="page-body bx--grid"
    >
      <router-view v-if="this.hasLoaded || !isAuthedRoute" />
    </section>
    <footer class="page-footer bx--grid">
      <span>tallytax.ie 2022 | Terms of service</span>
    </footer>
    <cv-loading
      :active="isLoadingInitial"
      :overlay="true"
    />
  </div>
</template>

<script>
import {
  CvHeader,
  CvHeaderGlobalAction,
  CvHeaderMenuItem,
  CvHeaderName,
  CvHeaderNav,
  CvSkipToContent,
  CvHeaderMenuButton,
  CvLoading,
  CvSideNav,
  CvSideNavItems,
  CvHeaderSideNavItems,
} from '@carbon/vue';

import Logout20 from '@carbon/icons-vue/es/logout/20';
import Settings20 from '@carbon/icons-vue/es/settings/20';

// eslint-disable-next-line import/no-webpack-loader-syntax
import { mapGetters } from 'vuex';
import GlobalStyles from './GlobalStyles.vue';
import { setAuthToken } from './lib/api';
import { SET_IS_LOGGED_IN } from './store/mutation-types';

export default {
  components: {
    CvHeader,
    CvHeaderNav,
    CvSkipToContent,
    CvHeaderMenuItem,
    CvHeaderName,
    CvHeaderGlobalAction,
    Logout20,
    Settings20,
    CvHeaderMenuButton,
    GlobalStyles,
    CvLoading,
    CvSideNav,
    CvSideNavItems,
    CvHeaderSideNavItems,
  },

  data() {
    return {
      loginSubscription: null,
      hasLoaded: false,
    };
  },

  computed: {
    ...mapGetters({
      assets: 'ledger/getAssets',
      isLoadingInitial: 'general/isLoading',
      isLoggedIn: 'general/isLoggedIn',
    }),

    shouldShowTopBar() {
      return this.isAuthedRoute;
    },

    isAuthedRoute() {
      return !['/register', '/login'].includes(this.$route.path);
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('general/setAuthToken');
      this.$router.push('/login');
    },

    settings() {
      this.$router.push('/settings');
    },

    async initialLoad() {
      await Promise.all([
        this.$store.dispatch('general/load'),
        this.$store.dispatch('assets/load'),
        this.$store.dispatch('products/load'),
        this.$store.dispatch('stockSplits/load'),
        this.$store.dispatch('transactions/load'),
        this.$store.dispatch('importPresets/load'),
      ]);
      this.$store.dispatch('ledger/calculate');
      this.hasLoaded = true;
    },
  },

  async mounted() {
    // Gotta set api auth here dayyum
    setAuthToken(this.$store.state.general.authToken);
    // this.$toasts.error('This is my favorite toasts plugin.');
    // this.$toasts.warning('This is my favorite toasts plugin.');
    // this.$toasts.success('This is my favorite toasts plugin.');
    // this.$toasts.base('This is my favorite toasts plugin.');
    // Loading lifecycle - trigger after we're certain the user is logged in
    this.loginSubscription = this.$store.subscribe(({ type, payload }) => {
      if (type !== `general/${SET_IS_LOGGED_IN}`) return;
      if (payload) this.initialLoad();
      // unsubscribe
      this.loginSubscription();
    });

    if (this.isAuthedRoute) {
      await this.$store.dispatch('general/checkIsLoggedIn');

      if (this.isLoggedIn) return;

      this.$router.push('/login');
    }
    // this.assets.map((asset) => this.$store.dispatch('assets/loadQuoteByUserTicker', asset.id));
  },

  beforeDestroy() {
    if (this.loginSubscription) this.loginSubscription();
  },
};
</script>

<style>
html, body {
   height: 100%;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

a {
  font-weight: 600;
  color: #333;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.page-body {
  min-width: 100%;
  padding-top: 5rem;
  flex: 1;
  background: #fff;
  border-top: 0;
  padding-bottom: 2rem;
}
.content-header {
  padding-bottom: 2rem;
}

@media (min-width: 80rem) {
  .page-body {
    max-width: 80rem;
    min-width: 80rem;
  }
}

.page-footer {
  border-top: 1px solid #e0e0e0;
  padding: 3rem 2rem;
  margin-top: 2rem;
  min-width: 100%;
}
#app {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}
</style>
